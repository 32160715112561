import { Component, OnInit } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, interval } from 'rxjs';
import { ApiService } from 'src/app/api.service';
import { BottomSheetComponent } from '../bottom-sheet/bottom-sheet.component';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styles: [`
    .mat-row:hover .mat-cell {
      cursor: pointer;
      background: whitesmoke;
    }
  `]
})
export class AppsComponent implements OnInit {

  displayedColumns: string[] = ['position', 'appName', 'serwerIp'];
  dataSource: any[] = [];

  constructor(private apiService: ApiService, private snackBar: MatSnackBar, private _bottomSheet: MatBottomSheet) { }

  subscription?: Subscription;

  ngOnInit(): void {
    this.loadData();
    this.subscription = interval(5*1000).subscribe(() => {
      this.loadData();
    });

  }

  loadData() {
    this.apiService.getInfoApp().subscribe(
      data => {
        this.dataSource = data;
      },
      error => {
        this.snackBar.open(error.error, undefined, { duration: 3 })
      }
    )
  }

  onRow(row: any) {
    this._bottomSheet.open(BottomSheetComponent, {
      data: row
    });
  }


  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
