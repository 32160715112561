<div class="row">
    <div class="col-lg-6">
        <mat-card class="mb-4">
            <h2>Aplikacje</h2>
            <div class="info-container">
                <app-apps></app-apps>
            </div>
        </mat-card>
    </div>

    <div class="col-lg-6">
        <mat-card class="mb-4">
            <h2>Serwery</h2>
            <div class="info-container">
                <app-servers></app-servers>
            </div>
        </mat-card>
        
        <mat-card class="mb-4">
            <div class="d-flex">
                <h2>Dyski</h2>
                <mat-form-field class="limit-form-field" appearance="fill">
                    <mat-label>Limit</mat-label>
                    <input matInput type="number" min="1" [(ngModel)]="limit">
                  </mat-form-field>
            </div>
            <div class="info-container">
                <app-hdds [limit]="limit"></app-hdds>
            </div>
        </mat-card>
    </div>
</div>