<mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="position">
      <mat-header-cell *matHeaderCellDef> No. </mat-header-cell>
      <mat-cell *matCellDef="let element; let i = index"> {{ i + 1 }} </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="appName">
      <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.appName }} </mat-cell>
    </ng-container>
  
    <ng-container matColumnDef="serwerIp">
      <mat-header-cell *matHeaderCellDef> IP </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.serwerIp }} </mat-cell>
    </ng-container>
  
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row
        *matRowDef="let row; columns: displayedColumns;"
        (click)="onRow(row)"
        [class]="row.statusAlert ? 'row-red' : 'row-green'"
    ></mat-row>
  </mat-table>