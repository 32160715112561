<div class="row">
  <div class="col-md-6 mx-auto">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">


      <mat-form-field class="w-100" color="primary" appearance="fill">
        <mat-label>Login</mat-label>
        <input matInput type="text" formControlName="username" required>
      </mat-form-field>
    
      <mat-form-field class="w-100" color="primary" appearance="fill">
        <mat-label>Hasło</mat-label>
        <input matInput type="password" formControlName="password" required>
      </mat-form-field>
    
    <div class="w-100 d-flex flex-column align-items-center">
      <button mat-flat-button color="primary" type="submit" class="align-self-center">Zaloguj</button>
    </div>
    
    </form>
  </div>
</div>