import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from './../../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  templateUrl: './login.component.html'
})
export class LoginComponent {

  form: FormGroup = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  });

  get f() {
    return this.form.controls;
  }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private snackBar: MatSnackBar
  ) {
  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const value = this.form.value;

    this.authService.login(value.username, value.password)
      .subscribe(
        data => {
          if (this.authService.checkToken(data)) {
            this.authService.signOut();
            this.authService.signIn(data);
            this.router.navigate(['/']);
          }
          else {
          }
        },
        error => {
          this.snackBar.open(error.error, undefined, {
            duration: 3000
          });
        }
      )
  }
}