import { Component, OnInit} from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { User } from '../auth/user';

@Component({
  templateUrl: './layout.component.html',
  styles: [
  ]
})
export class LayoutComponent implements OnInit {


  public loggedUser: User | null | undefined;

  public logout() {
    this.authService.logout();
  }

  constructor (
private authService: AuthService
  ) {
    this.authService.currentUser.subscribe(user => {
      this.loggedUser = user;
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }


}
