import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './home.component.html',
  styles: [

  ]
})
export class HomeComponent implements OnInit {

  limit: number = 1;

  constructor() { }

  ngOnInit(): void {
  }

}
