
import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import { JwtHelperService } from "@auth0/angular-jwt";
import { User } from './user';
import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';



// =============' KEY IN LOCALSTORAGE '==============
const TOKEN_KEY   = 'auth-token';
// =================================================



@Injectable()
export class AuthService {

  private jwtHelper = new JwtHelperService();

  private currentUserSubject: BehaviorSubject<User|null>;
  public currentUser: Observable<User|null>;

  constructor(private http: HttpClient, private router: Router) {
    this.currentUserSubject = new BehaviorSubject<User|null>(this.getCurrentUser());
    this.currentUser = this.currentUserSubject.asObservable();
}

reloadCurrentRoute() {
  let currentUrl = this.router.url;
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
  });
}

public get currentUserValue(): User|null {
  return this.currentUserSubject.value;
}


private getCurrentUser(): User|null {
  const token = this.getToken();
  // console.log(token)
  if (token == null) {
    return null;
  }
  try {
    const decodedToken = this.jwtHelper.decodeToken(token);

    const user = {
      username: decodedToken.sub,
      role: decodedToken.role,
      uuid: decodedToken.iss
    };
    return user;
  } catch(_) {
    return null;
  }
  
}



  login(username: string, password: string): Observable<any> {
    return this.http.post(`/api/user/authorization-user/`, {
      username, password
    }, {
      // params: new HttpParams().set('username', username).set('password', password),
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }


  savePassword(token: string, password: string): Observable<any> {
    return this.http.post(`/api/new-password/email-token/${token}`, password, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });
  }

  resetPassword(email: string): Observable<any> {
    return this.http.get(`/api/new-password/email/${email}`, {
      headers: new HttpHeaders().set('Accept','*/*'),
      responseType: 'text'
    });

  }

  /**
   * Verify if User is Connected
   */
  isLoggedIn(): boolean {
    return !!this.getToken();
  }
  // =================================================
  // 🔐🔐 TOKEN MANAGER 🔐🔐
  // =================================================

  /**
   * Clears the localstorage and Disconnect the User
   * from the Front-End
   *
   */
  signOut(): void {
    localStorage.removeItem(TOKEN_KEY);
    if (this.currentUserSubject)
      this.currentUserSubject.next(null);
  }

  logout(): void {
    this.signOut();
    this.reloadPage();
  }

  private reloadPage(): void {
    window.location.reload();
  }

  signIn(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
    this.currentUserSubject.next(this.getCurrentUser());
  }



  /**
   * Get The Token Stored in LocalStorage
   * ¤ If Exist
   */
  public getToken(): string | null {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token != null) {
      if (!this.checkToken(token)) {
        this.logout();
        return null;
      }
    }
    return token;
  }


  public checkToken(token: string): boolean {
    try {
      this.jwtHelper.decodeToken(token);
      // console.log(this.jwtHelper.decodeToken(token))
      return !this.jwtHelper.isTokenExpired(token);
    }
    catch(_) {
      return false;
    }
  }


}
