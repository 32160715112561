

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { Observable, from, of, throwError } from "rxjs";
import { map, switchMap } from "rxjs/operators";


@Injectable({
  providedIn: 'root',
})
export class ApiService {

  constructor(private http: HttpClient)
  { }
  
  getInfoApp(): Observable<any[]> {
    return this.http.get<any[]>(`/api/monitoring/out/add-info-app`);
  }

  getInfoAppPRD(): Observable<any[]> {
    return this.http.get<any[]>(`/api/monitoring/out/add-info-app`);
  }

  getInfoServer(): Observable<any[]> {
    return this.http.get<any[]>(`/api/monitoring/out/add-info-serwer`);
  }

  getInfoHdd(limit: number): Observable<any[]> {
    return this.http.get<any>(`/api/monitoring/out/add-info-hdd/limit/${limit}`).pipe(
      map(data => {
        let hdds: any[] = [];
        Object.entries(data).forEach(([key, value]) => {
          hdds.push(...value as any[]);
        });
        return hdds;
      })
    )
  }


  // addCarVin(car: any): Observable<any> {
  //   return this.http.post(`/api/auth/car-vin`, car, {
  //     headers: new HttpHeaders().set('Accept','*/*'),
  //     responseType: 'text',
  //     observe: 'response'
  //   });
  // }

}


